<script>
import { cloneDeep } from "lodash"
import CompanyLogic from "@/logics/CompanyLogic"

export default {
  props: {
    simulation: Object,
  },
  data() {
    return {
      has_phishing_custom_error_page: null,
      defaultRedirectUrl: "",
      customRedirectUrl: "",
      currentRedirectUrl: "",
      localSimulation: null,
      hasInitialized: false,
      phishingExerciseId: null,
      isCustomUrlValid: true,
    }
  },
  watch: {
    simulation: {
      immediate: true,
      deep: true,
      handler(newValue) {

        if (newValue) {
          this.localSimulation = cloneDeep(newValue)

          // first load
          if (!this.hasInitialized) {
            this.has_phishing_custom_error_page = newValue.has_phishing_custom_error_page || false
            this.customRedirectUrl = newValue.phishing_custom_error_page?.url || ""
            this.hasInitialized = true
          }

          this.phishingExerciseId = newValue.phishing_exercise_id

          this.updateDefaultRedirectUrl()
          this.updateCurrentRedirectUrl()
        } else if (newValue.phishing_exercise_id !== this.phishingExerciseId) {
          this.phishingExerciseId = newValue.phishing_exercise_id
          this.updateDefaultRedirectUrl()
        }
      },
    },
    has_phishing_custom_error_page: "updateCurrentRedirectUrl",
    customRedirectUrl: "updateCurrentRedirectUrl",
    defaultRedirectUrl: "updateCurrentRedirectUrl",
  },
  methods: {
    async getLandingPageFromPhishingExercise() {

      if (!this.localSimulation || !this.localSimulation.phishing_exercise_id) {
        return
      }

      return await CompanyLogic.getCompanyExerciseLandingPage(
        this.localSimulation.company_uuid,
        this.localSimulation.phishing_exercise_id,
      )
    },

    validateCustomRedirectUrl() {
      const urlPattern = /^(https?:\/\/)/
      this.isCustomUrlValid = urlPattern.test(this.customRedirectUrl)
    },
    
    async updateDefaultRedirectUrl() {
      const landingPage = await this.getLandingPageFromPhishingExercise()

      if (landingPage) {

        if (landingPage.redirect_url.startsWith("http")) {
          this.defaultRedirectUrl = landingPage.redirect_url
        } else {
          this.defaultRedirectUrl = new URL(landingPage.redirect_url, landingPage.url).href
        }
      } else {
        this.defaultRedirectUrl = null
      }
    },

    updateCurrentRedirectUrl() {
      this.currentRedirectUrl = this.has_phishing_custom_error_page
        ? this.customRedirectUrl
        : this.defaultRedirectUrl
    },

    openRedirectUrl() {
      if (this.currentRedirectUrl) {
        window.open(this.currentRedirectUrl, "_blank")
      }
    },

    getUpdatedData() {
      // Returns current state for parent to save
      const customErrorPage = {
        url: this.has_phishing_custom_error_page ? this.customRedirectUrl : this.defaultRedirectUrl,
      }
      let data =  {
        has_phishing_custom_error_page: this.has_phishing_custom_error_page,
        phishing_custom_error_page: customErrorPage,
        error: false,
        errorMessage: "",
      }

      if(!this.isCustomUrlValid){
        data.error= true;
        data.errorMessage = this.$t('phishingSimulations.edition.invalidUrlWarning')
      }

      return data
    },
  },
}
</script>
<template>
  <v-card elevation="0" outlined color="#fafafa">
    <div class="main-container pa-4" v-if="localSimulation">
      <v-row align="center" class="mb-4">
        <!-- Toggle Custom Error Page Mode -->
        <v-col cols="12" class="d-flex align-center justify-space-between">
          <v-switch
            v-model="has_phishing_custom_error_page"
            :label="$t('phishingSimulations.edition.customErrorPage')"
          />
        </v-col>
      </v-row>
      <v-row>
        <!-- Redirect Button -->
        <v-col cols="6">
          <div class="text-h3 mb-5">{{ $t("phishingSimulations.edition.preview") }}</div>
          <v-btn
            v-if="currentRedirectUrl"
            @click="openRedirectUrl"
            color="primary"
          >
            {{ $t("phishingSimulations.edition.viewpage") }}
          </v-btn>
        </v-col>

        <!-- URL Display/Edit Section  -->
        <v-col cols="6">
          <div class="text-h3 mb-5">
            {{ has_phishing_custom_error_page
            ? $t("phishingSimulations.edition.customUrl")
            : $t("phishingSimulations.edition.defaultUrl") }}
          </div>

          <!-- Display non-editable default URL in standard mode -->
          <v-text-field
            v-if="!has_phishing_custom_error_page"
            :label="$t('phishingSimulations.edition.defaultUrl')"
            :value="defaultRedirectUrl"
            readonly
            outlined
          ></v-text-field>

          <!-- Editable custom URL field in custom mode -->
          <v-text-field
            v-else
            v-model="customRedirectUrl"
            :label="$t('phishingSimulations.edition.customUrl')"
            placeholder="Enter custom URL"
            outlined
            @input="validateCustomRedirectUrl"
            :error="!isCustomUrlValid"
            :error-messages="isCustomUrlValid ? '' : $t('phishingSimulations.edition.invalidUrlWarning')"

          ></v-text-field>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<style scoped>
.main-container {
  min-height: 80vh;
}

.text-h3 {
  font-size: 1.15rem !important;
  font-weight: 500;
}

.frame {
  border: 1px dotted #e0e0e0;
  background-color: #fff;
}

.full-size {
  width: 100%;
  height: calc(100% - 64px);
  overflow: auto;
}
</style>

