import LocalStorage from "../services/LocalStorage"
import AuthRepository from "../repositories/AuthRepository"
import moment from "moment"
import AuthStore from "../stores/AuthStore"
import LocaleLogic from "./LocaleLogic"
import Model from "../models/Model"
import { showSnackbar } from "@/services/GlobalActions"
import UserRepository from "@/repositories/UserRepository"
import DashboardStore from "../stores/DashboardStore"
import { i18n } from "../plugins/i18n"

export default class AuthLogic {

  static register(body) {
    return AuthRepository.register(body)
  }

  static async login(body) {
    AuthLogic.set2FAIsNeeded(false)
    const response = await AuthRepository.login(body)
    if (response.status_code === 200) {
      const mustSend2faCode = response.responseObject().two_factor
      AuthLogic.set2FAIsNeeded(mustSend2faCode)
      AuthLogic.setTokens(response.responseObject())
      if (!mustSend2faCode) {
        return await this.loadMe()
      }
      return { mustSend2faCode }
    }
  }

  static async loadMe() {
    const response = await AuthRepository.me()

    if (response.status_code !== 200) {
      return null;
    }

    const user = response.dataObject()
    if (false === user.can_access_one) {
      showSnackbar(
        "Vous n'avez pas les droits suffisants pour accéder à cette plateforme"
      )
      AuthLogic.removeTokens()
      return null
    }

    AuthLogic.setMe(user)
    return user
  }

  /**
   * store user in class memory & local storage
   */
  static setMe(user) {
    i18n.locale = user.lang
    AuthLogic.computeFullNameAndInitials(user)
    AuthStore.setMe(user)
    LocalStorage.set("me", user)
  }

  static computeFullNameAndInitials(user) {
    const firstName = user.first_name
    const lastName = user.last_name
    const email = user.email_address

    if (firstName && lastName) {
      user.full_name = firstName + " " + lastName;
      user.initials = firstName.substr(0, 1) + lastName.substr(0, 1);
    } else if (lastName) {
      user.full_name = lastName;
      user.initials = lastName.substr(0, 2);
    } else if (firstName) {
      user.full_name = firstName;
      user.initials = firstName.substr(0, 2);
    } else if (email) {
      let emailSplit = email.split("@")[0]
      user.full_name = emailSplit;
      user.initials = emailSplit.substr(0, 2);
    }
  }

  static async refreshToken(refreshToken) {
    this.deleteRefreshToken()
    const response = await AuthRepository.refreshToken(refreshToken)
    AuthLogic.setTokens(response.responseObject())
    return response.responseObject()
  }

  static deleteRefreshToken() {
    let token = LocalStorage.get("token")
    delete token.refresh_token
    AuthLogic.setTokens(token)
  }

  static async logout() {
    if (this.getTokens()) {
      await AuthRepository.logout()
    }

    this.removeMe()
    this.removeTokens()

    return true
  }

  /**
   * @returns  {Promise<string>}
   */
  static async forgotPassword(email) {
    const response = await AuthRepository.forgotPassword({
      email: email,
      locale: LocaleLogic.getBrowserLanguage(),
    })

    return new Model(response.dataObject())
  }

  static async resetPassword(email) {
    const response = await AuthRepository.resetPassword({
      email: email,
      locale: LocaleLogic.getBrowserLanguage()
    })

    return new Model(response.dataObject())
  }

  /**
   * @returns {Model}
   */
  static me() {
    return new Model(LocalStorage.get("me"))
  }

  /**
   * @returns void
   */
  static removeMe() {
    LocalStorage.remove("me")
    AuthStore.reset()
    DashboardStore.reset()
  }

  /**
   *
   * @returns {boolean}
   */
  static hasAccessToken() {
    return !!this.getTokens()
  }

  /**
   * @param token
   */
  static setTokens(token) {
    token.expireDate = moment().add(token.expires_in, "seconds")
    LocalStorage.set("token", token)
    AuthStore.setToken(token)
  }

  /**
   * @returns {string}
   */
  static getTokens() {
    return LocalStorage.get("token")
  }

  /**
   * @returns {string}
   */
  static removeTokens() {
    LocalStorage.remove("token")
    AuthStore.state.token = null
  }

  /**
   * @param needs2FA
   */
  static set2FAIsNeeded(needs2FA) {
    LocalStorage.set("needs2FA", needs2FA)
  }

  /**
   * @returns {boolean}
   */
  static get2FAIsNeeded() {
    return LocalStorage.get("needs2FA")
  }

  static async refreshRoles() {
    const response = await UserRepository.getUserRoles()
    const roles = response.responseObject().data
    AuthStore.setRoles(roles)

    return roles
  }
}
