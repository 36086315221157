<script>
import PhishingLogic from "@/logics/PhishingLogic"
import CompanyLogic from "@/logics/CompanyLogic"
import { VueEditor } from "vue2-quill-editor"
import Quill from "quill"
import "quill/dist/quill.snow.css"
import { cloneDeep, debounce } from "lodash"
import beautify from "js-beautify"
import imageUploadMixin from "@/mixins/imageUploadMixin"
import DOMPurify from "dompurify"

export default {
  components: {
    //VueEditor,
  },
  props: {
    simulation: Object,
    exerciseTags: Object,
    htmlContentPropName: {
      type: String,
      default: "localCustomLandingPageHtml",
    },
  },
  mixins: [imageUploadMixin],
  data() {
    return {
      has_phishing_custom_landing_page: null,
      localTags: null,
      localCustomLandingPageHtml: null,
      localSimulation: null,
      landingPageTemplate: null,
      savedTags: null,
      savedCustomLandingPageHtml: null,
      savedCustomLandingPageData: {},
      rawHTMLModeOn: false,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ "list": "ordered" }, { "list": "bullet" }],
        ["image", "code-block"],
      ],
      isQuillPopupOpen: false,
      quillEditorInstance: null,
      quillEditorContentHtml: "",
      showDisclaimer: false,
      hasOpenedQuillEditor: false,
      isHTMLPurifyDialogOpen: false,
      purifiedErrors: [],
      purifiedHtml: "",
    }
  },
  watch: {
    simulation: {
      immediate: true,
      handler(newValue) {
        if (newValue) {

          //on prends en compte le changement de simulation que si l'id de l'exercice phishing change ou si c'est la première fois qu'on charge la simulation
          if (!this.localSimulation ||
            this.localSimulation.phishing_exercise_id !== newValue.phishing_exercise_id) {
            this.localSimulation = cloneDeep(newValue)
            if (this.has_phishing_custom_landing_page === null) {
              this.has_phishing_custom_landing_page = newValue.has_phishing_custom_landing_page
            }
            this.switchMode()
          }
        }
      },
    },
    exerciseTags: {
      immediate: true,
      deep: true,
      async handler(newTags) {
        this.localTags = cloneDeep(newTags)
        await this.refreshTemplate()
      },
    },
    has_phishing_custom_landing_page: "switchMode",
  },
  methods: {
    async switchMode() {
      console.log("switch LP m.o.d.e")

      if (this.has_phishing_custom_landing_page) {
        // Switching to HTML mode: Save the current tags and restore custom data
        this.savedTags = JSON.parse(JSON.stringify(this.localTags))

        if (!this.savedCustomLandingPageHtml &&
          !this.localSimulation.phishing_custom_landing_page) {
          // Init with phishing exercise template
          await this.fillCustomHtmlFromPhishingExercise()
        } else {
          // restore previously saved
          this.localCustomLandingPageHtml = this.savedCustomLandingPageHtml || this.localSimulation.phishing_custom_landing_page?.html || ""
        }

        // Clear the compiled landing page template
        this.landingPageTemplate = null
      } else {
        // Switching back to standard mode: Save current custom HTML and data
        this.savedCustomLandingPageHtml = this.localCustomLandingPageHtml

        // Restore saved tags and recompile landing page template with tags
        if (this.savedTags) {
          this.localTags = JSON.parse(JSON.stringify(this.savedTags))
        }

        await this.refreshTemplate()
      }
    },
    async fillCustomHtmlFromPhishingExercise() {
      if (!this.localSimulation || !this.localSimulation.phishing_exercise_id) {
        return
      }

      const data = await CompanyLogic.getCompanyExerciseCompiledTemplates(
        this.localSimulation.company_uuid,
        this.localSimulation.phishing_exercise_id,
      )
      const landingPageTemplate = data?.landingPage
      if (landingPageTemplate) {
        this.localCustomLandingPageHtml = this.formatHtml(landingPageTemplate.html)
      }
    },
    async getLandingPageFromPhishingExercise() {

      if (!this.localSimulation || !this.localSimulation.phishing_exercise_id) {
        return
      }

      return await CompanyLogic.getCompanyExerciseLandingPage(
        this.localSimulation.company_uuid,
        this.localSimulation.phishing_exercise_id,
      )
    },
    async onTagInputChange() {
      this.$emit("update-tags", this.localTags)
      await this.refreshTemplate()
    },
    formatCustomHtml() {
      this.localCustomLandingPageHtml = this.formatHtml(this.localCustomLandingPageHtml)
    },
    formatHtml(htmlContent) {
      return beautify.html(htmlContent, {
        indent_size: 2,                // Sets indentation size
        wrap_line_length: 0,           // Prevents wrapping by setting line length to zero
        preserve_newlines: false,      // Removes line breaks between tags
        max_preserve_newlines: 0,      // Limits max number of consecutive empty lines to zero
        unformatted: ["a", "span"],    // List tags not to format (modify as needed)
        extra_liners: [],               // Prevents adding extra line breaks around specific tags
      })
    },
    refreshTemplate: debounce(async function() {
      await this.compileTemplate()
    }, 300),
    async compileTemplate() {
      if (this.has_phishing_custom_email || !this.localSimulation.phishing_exercise_id) return

      try {
        const companyUuid = this.localSimulation.company_uuid
        const exerciseId = this.localSimulation.phishing_exercise_id

        console.log("compile LP Template ", companyUuid, exerciseId)

        if (!companyUuid) {
          console.error("Missing companyUuid ", this.localSimulation)
          return
        }

        let result
        if (this.localTags) {
          // Call backend to compile the landing page with tags
          result = await CompanyLogic.getCompanyExerciseCompiledWithTemplateTags(
            companyUuid,
            exerciseId,
            this.localTags,
          )
        } else {
          // Call backend to get template without tags
          result = await PhishingLogic.getExerciseCompiledTemplates(
            exerciseId,
          )
        }

        if (result?.landingPage?.html) {
          this.landingPageTemplate = result.landingPage.html
        }
      } catch (error) {
        console.error("Failed to refresh template:", error)
      }
    },
    openQuillEditor() {
      if (!this.has_phishing_custom_landing_page) {
        console.error("Cannot open Quill editor in standard mode")
        return
      }

      // Show disclaimer only the first time Quill editor is used
      if (!this.hasOpenedQuillEditor) {
        this.showDisclaimer = true
      } else {
        this.openQuillEditorDialog()
      }
    },
    openQuillEditorDialog() {
      try {
        //let sanitizedHtml = this.sanitizeHtmlWithDOMPurify(this.localCustomLandingPageHtml);
        this.quillEditorContentHtml = this.localCustomLandingPageHtml  //sanitizedHtml;
        this.isQuillPopupOpen = true
        this.quillError = false // Réinitialiser l'état d'erreur

        this.$nextTick(() => {
          if (!this.quillEditorInstance) {
            console.log("** init quill instance **")
            this.quillEditorInstance = new Quill(this.$refs.quillEditor, {
              theme: "snow",
              modules: {
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline"],
                  [{ "list": "ordered" }, { "list": "bullet" }],
                  ["image", "code-block"],
                ],
                clipboard: {
                  matchVisual: false,
                },
              },
            })
          }

          this.quillEditorInstance.root.innerHTML = this.quillEditorContentHtml
        })
      } catch (error) {
        console.error("Erreur lors de l'initialisation de Quill :", error)
        this.quillError = true
        this.quillErrorMessage = "Le HTML fourni n'est pas supporté ou contient des éléments non valides."
        this.isQuillPopupOpen = false
        this.quillEditorInstance = null
      }
    },
    confirmDisclaimer() {
      this.hasOpenedQuillEditor = true
      this.showDisclaimer = false
      this.openQuillEditorDialog()
    },
    saveQuillEditorContent() {
      this.localCustomLandingPageHtml = this.quillEditorInstance.root.innerHTML
      //this.localCustomLandingPageHtml = this.quillEditorContentHtml // Update main content with sanitized HTML
      this.isQuillPopupOpen = false
    },
    closeQuillEditor() {
      console.log("close quill editor")
      this.isQuillPopupOpen = false
      this.quillEditorContentHtml = "" // Clear content
    },
    sanitizeHtmlWithQuill(html) {
      const originalHTML = cloneDeep(html)
      const tempDiv = document.createElement("div")
      document.body.appendChild(tempDiv)

      const tempQuill = new Quill(tempDiv, {
        modules: {
          toolbar: false,
          clipboard: {
            matchVisual: false,
          },
        },
        theme: "snow", // Utiliser un thème pour une meilleure compatibilité
      })

      const delta = tempQuill.clipboard.convert(originalHTML)
      tempQuill.setContents(delta)
      const sanitizedHtml = tempQuill.root.innerHTML

      // Nettoyer le DOM
      document.body.removeChild(tempDiv) // Clean up

      console.log("Original HTML:", originalHTML)
      console.log("Sanitized HTML:", sanitizedHtml)

      return sanitizedHtml
    },
    async getUpdatedData() {
      const landingPage = await this.getLandingPageFromPhishingExercise()

      let customLandingPage = {
        capture_credentials: true,
        capture_password: false,
        name: "",
        redirect_url: "",
        url: "",
        html: this.localCustomLandingPageHtml,
      }

      if (landingPage) {
        customLandingPage.url = landingPage.url
        customLandingPage.redirect_url = landingPage.redirect_url
      }

      return {
        has_phishing_custom_landing_page: this.has_phishing_custom_landing_page,
        exerciseTags: this.localTags,
        phishing_custom_landing_page: customLandingPage,
      }
    },
    async showPurifiedHtml() {
      try {
        const content = await CompanyLogic.purifyHtml(
          this.localSimulation.company_uuid,
          this.localCustomLandingPageHtml)

        this.purifiedHtml = content.purifiedHtml
        this.purifiedErrors = content.errors

        this.isHTMLPurifyDialogOpen = true

      } catch (error) {
        console.error("Erreur lors de la récupération du contenu purifié :", error)
      }
    },
    validatePurifiedHtml() {
      this.localCustomLandingPageHtml = this.purifiedHtml
      this.closeHtmlPurifyDialog()
    },
    closeHtmlPurifyDialog() {
      this.isHTMLPurifyDialogOpen = false
    },
  },
}
</script>

<template>
  <v-card elevation="0" outlined color="#fff">
    <div class="main-container" v-if="localSimulation">
      <!-- Top Controls Section -->
      <v-row align="center" class="mb-4">
        <v-col cols="12" class="d-flex align-center justify-space-between">
          <!-- Toggle Custom Landing Page Mode -->
          <v-switch
            v-model="has_phishing_custom_landing_page"
            :label="$t('phishingSimulations.edition.customLandingPage')"
          />

          <!-- Main Action Buttons -->
          <div>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="showPurifiedHtml"
                       :disabled="!has_phishing_custom_landing_page"
                       class="ma-2"
                       v-bind="attrs"
                       v-on="on">
                  <v-icon color="green">mdi-language-html5</v-icon>
                </v-btn>
              </template>
              <span> Purified HTML</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="fillCustomHtmlFromPhishingExercise"
                  class="ma-2"
                  :disabled="!has_phishing_custom_landing_page"
                  v-bind="attrs"
                  v-on="on">
                  <v-icon color="green">mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span> {{ $t("phishingSimulations.edition.loadDefaultHTML") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="openQuillEditor"
                       class="ma-2"
                       :disabled="!has_phishing_custom_landing_page"
                       v-bind="attrs"
                       v-on="on">
                  <v-icon color="green">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span> {{ $t("phishingSimulations.edition.openHtmlEditor") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="triggerImageUpload"
                       class="ma-2"
                       :disabled="!has_phishing_custom_landing_page"
                       v-bind="attrs"
                       v-on="on">
                  <v-icon color="green">mdi-image-plus</v-icon>
                </v-btn>
              </template>
              <span> {{ $t("phishingSimulations.edition.imageInsert") }}</span>
            </v-tooltip>

            <input
              ref="imageInput"
              type="file"
              accept="image/*"
              style="display: none"
              @change="handleImageUpload"
            />

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-2"
                       @click="formatCustomHtml"
                       :disabled="!has_phishing_custom_landing_page"
                       v-bind="attrs"
                       v-on="on">
                  <v-icon color="green">mdi-format-align-left</v-icon>
                </v-btn>
              </template>
              <span> {{ $t("phishingSimulations.edition.indentCode") }}</span>
            </v-tooltip>

          </div>
        </v-col>
      </v-row>

      <!-- Mode Custom -->
      <v-row v-if="has_phishing_custom_landing_page">

        <!-- Preview on the right -->
        <v-col cols="6">
          <div class="preview-container">
            <div class="text-h3 mb-5">{{ $t("phishingSimulations.preview.preview") }}</div>

            <!-- Display landing page body preview -->
            <iframe
              ref="landingPageIframe"
              :srcdoc="localCustomLandingPageHtml"
              class="frame full-size"
            ></iframe>
          </div>
        </v-col>

        <v-col cols="6">
          <div class="text-h3 mb-5">{{ $t("phishingSimulations.preview.editable_content") }}</div>
          <v-textarea
            @click="updateCursorPosition"
            @keyup="updateCursorPosition"
            label="Raw HTML"
            v-model="localCustomLandingPageHtml"
            rows="20"
            class="responsive-textarea"
            outlined
            placeholder="Edit HTML directly"
          ></v-textarea>
        </v-col>
      </v-row>

      <!-- Mode standard (with tags) -->
      <v-row v-else>
        <!-- Visualization -->
        <v-col cols="8" class="mt-4">
          <div class="preview-container">
            <div class="text-h3 mb-5">{{ $t("phishingSimulations.preview.preview") }}</div>

            <!-- Display landing page body -->
            <iframe
              ref="landingPageIframe"
              :srcdoc="landingPageTemplate"
              class="frame full-size"
            ></iframe>
          </div>
        </v-col>

        <!-- Editable Tags Section -->
        <v-col cols="4" class="mt-4">
          <div class="text-h3 mb-5">{{ $t("view.companies.editable_tags") }}</div>
          <v-form v-if="localTags?.landingPage?.tags" class="pl-2 pr-2">
            <div v-for="(value, key, index) in localTags.landingPage.tags" :key="index">
              <v-text-field
                :label="key"
                v-model="localTags.landingPage.tags[key].value"
                outlined
                @input="onTagInputChange"
              ></v-text-field>
            </div>
          </v-form>
          <v-card-text v-else type="info" class="mt-2">
            {{ $t("view.companies.template_contains_no_tags") }}
          </v-card-text>
        </v-col>
      </v-row>
    </div>

    <!-- Quill Editor Popup -->
    <v-dialog v-model="isQuillPopupOpen" max-width="800px">
      <v-card>
        <v-card-text class="pt-4">
          <!--vue-editor v-model="quillEditorContentHtml" ref="htmlEditor" :editorToolbar="customToolbar"></vue-editor-->
          <div ref="quillEditor"></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="saveQuillEditorContent">{{ $t("buttons.save") }}</v-btn>
          <v-btn @click="closeQuillEditor">{{ $t("buttons.cancel") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Disclaimer Dialog -->
    <v-dialog v-model="showDisclaimer" max-width="600px">
      <v-card>
        <v-card-title>{{ $t("phishingSimulations.edition.disclaimerTitle") }}</v-card-title>
        <v-card-text>{{ $t("phishingSimulations.edition.quillDisclaimerMessage") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="confirmDisclaimer">{{ $t("buttons.continue") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="isHTMLPurifyDialogOpen" max-width="1400px">
      <v-card>
        <v-card-title>
          <span>Prévisualisation du contenu purifié</span>
        </v-card-title>

        <v-card-text>
          <div class="errors-section">
            <h4>Erreurs détectées :</h4>
            <template v-if="purifiedErrors.length > 0">
              <ul>
                <li v-for="(error, index) in purifiedErrors" :key="index">
                  Ligne {{ error[0] }}, Colonne {{ error[1] }} : {{ error[2] }}
                </li>
              </ul>
            </template>
            <p v-else>Aucune erreur détectée. 🎉</p>
          </div>
          <div>
            <h4>Contenu HTML Purifié :</h4>
            <iframe
              :srcdoc="purifiedHtml"
              style="width: 100%; height: 300px; border: 1px solid #ccc;">
            </iframe>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="validatePurifiedHtml">Valider</v-btn>
          <v-btn color="grey" @click="closeHtmlPurifyDialog">Annuler</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<style scoped>
.text-h3 {
  font-size: 1.15rem !important;
  font-weight: 500;
}

.frame {
  border: 1px dotted #e0e0e0;
  background-color: #fff;
}

.full-size {
  width: 100%;
  height: calc(100% - 64px);
  overflow: auto;
}

.main-container {
  padding: 10px;
}

.preview-container {
  height: calc(100vh - 70px - 52px - 12px);
}
</style>
