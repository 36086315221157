export default {
  props: {
    htmlContentPropName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cursorPosition: 0,  // last known cursor position
      customHtml: "",
    };
  },

  methods: {
    updateCursorPosition(event) {
      this.cursorPosition = event.target.selectionStart;
    },
    triggerImageUpload() {
      this.$refs.imageInput.click();
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      // Limit file size to 2MB
      const maxSize = 2 * 1024 * 1024;
      if (file.size > maxSize) {
        this.$emit('error', 'File size exceeds 2MB');
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Image = e.target.result;
        this.insertImageAtCursor(base64Image);
        // Reset the file input value to allow uploading the same file again
        this.$refs.imageInput.value = null;
      };
      reader.readAsDataURL(file); // Convert image file to base64
    },
    insertImageAtCursor(base64Image) {
      const imageTag = `<img src="${base64Image}" alt=""/>`;
      const htmlContent = this[this.htmlContentPropName] || '';
      this[this.htmlContentPropName] = [
        htmlContent.slice(0, this.cursorPosition),
        imageTag,
        htmlContent.slice(this.cursorPosition),
      ].join('');

      // Move the cursor position after the newly inserted image
      this.cursorPosition += imageTag.length;
    },
  },
};
