<script>
import { i18n } from "@/plugins/i18n"
import { debounce } from "lodash"
import moment from "moment"

moment.locale("fr")

export default {
  props: {
    value: Object,   // Simulation object
    campaigns: Array,
    employeeGroups: Array,
    exercises: Array,
    isSystemAdmin: Boolean,
    exerciseTags: Object,
  },
  data() {
    return {
      localSimulation: null,
      selectedEmployeeGroupIds: [],
      showModalExercice: false,  // Control the visibility of the exercise selection modal
      selectedExercise: null,
      selectedExerciseTitle: null,
      todayString: new Date().toISOString().substr(0, 10),  // Today's date
      requiredRule: v => !!v || "Field is required",  // Required field rule
      search: "",  // Search string for exercises
      selectedLanguage: "",
      langArray: [
        { code: "", label: i18n.t("view.learning_phishing.all_languages") },
        { code: "de", label: "Deutsch" },
        { code: "nl", label: "Dutch" },
        { code: "en", label: "English" },
        { code: "es", label: "Español" },
        { code: "fr", label: "Français" },
        { code: "it", label: "Italiano" },
        { code: "pl", label: "Polska" },
        { code: "pt", label: "Português" },
        { code: "ro", label: "Română" },
        { code: "sv", label: "Svenska" },
        { code: "tr", label: "Türkçe" },
      ],
      errorMessage: false, // Tracks whether there's an error for automatic launch message
      showButton: false,  // Show the save button
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {

        if (!newValue) return;

        // Initialize `selectedEmployeeGroupIds` only if `employee_group_ids` has changed
        const newGroupIds = newValue.employee_group_ids || [];
        if (JSON.stringify(this.selectedEmployeeGroupIds) !== JSON.stringify(newGroupIds)) {
          this.selectedEmployeeGroupIds = newGroupIds;
        }

        // init selected exercise
        if(newValue.phishing_exercise_id){
          this.selectedExercise =
            this.exercises.find((e) => e.id === newValue.phishing_exercise_id) || {}
            this.selectedExerciseTitle = this.selectedExercise.title
        }

        // Deep copy the simulation to avoid mutations
        this.localSimulation = JSON.parse(JSON.stringify(newValue))
        this.updateAutomaticLaunchMessage()
      },
    },
    // Only watch specific fields, not the entire object
    "localSimulation.name": function(newName) {
      this.checkForChanges()
    },
    "localSimulation.campaign_id": function(newCampaignId) {
      this.checkForChanges()
    },
    "localSimulation.is_test": function(newCampaignId) {
      this.checkForChanges()
    },
    "localSimulation.launch_date": function(newCampaignId) {
      this.checkForChanges()
    },
    "localSimulation.send_by_date": function(newCampaignId) {
      this.checkForChanges()
    },
    "localSimulation.launch_time": function(newCampaignId) {
      this.checkForChanges()
    },
    "localSimulation.send_by_time": function(newCampaignId) {
      this.checkForChanges()
    },
    "localSimulation.phishing_exercise_id": function(newCampaignId) {
      this.checkForChanges()
    },
    "localSimulation.has_automatic_launch": function(newCampaignId) {
      this.checkForChanges()
    },
    "localSimulation.has_automatic_stop": function(newCampaignId) {
      this.checkForChanges()
    },
    selectedEmployeeGroupIds: function(newEmployeeGroups) {
      this.checkForChanges()
    },
  },
  methods: {
    async updateLaunchDate(data) {

      this.showButton = true

      this.localSimulation.launch_date = data

      this.updateAutomaticLaunchMessage()

      const launchDate = new Date(this.localSimulation.launch_date)
      const isOk = launchDate <= new Date()

      if (isOk) {
        if (
          await this.$refs.confirm.open(
            i18n.t("view.companies.confirm_start_date_shipment"),
            i18n.t("view.companies.confirm_start_date_shipment_message"),
            i18n.t("view.custom_editor.modify"),
          )
        ) {
          this.canSave = true
        }
      }
    },
    // Manage the message under 'Automatic Start-up' switch
    updateAutomaticLaunchMessage() {
      if(!this.localSimulation) return
      const launchDate = moment(this.localSimulation.launch_date)
      const correctDate = moment().add(1, "day")
      this.errorMessage = launchDate.isBefore(correctDate)
    },
    // Format the deadline for changing the launch date
    formatDeadLineDate(simulation) {
      const launchDate = moment(simulation.launch_date)
      launchDate.subtract(1, "day")  // Subtract 1 day from the launch date
      const formattedDate = launchDate.format("dddd D MMMM YYYY")
      return ` ${formattedDate} ${i18n.t("view.companies.at_8AM")}`
    },
    updateSendByDate(date) {
      console.log("update sendby date")
      this.localSimulation.send_by_date = date
    },
    selectExercise(exercise) {
      this.localSimulation.phishing_exercise_id = exercise.id
      this.selectedExercise = exercise
      this.selectedExerciseTitle = exercise.title
      this.showModalExercice = false
    },
    // Function to compare specific fields and detect changes
    hasSimulationChanged() {
      const original = this.value
      const current = this.localSimulation

      if (
        original.name !== current.name ||
        JSON.stringify(original.employee_group_ids) !== JSON.stringify(this.selectedEmployeeGroupIds) ||
        original.campaign_id !== current.campaign_id ||
        original.is_test !== current.is_test ||
        original.launch_date !== current.launch_date ||
        original.send_by_date !== current.send_by_date ||
        original.launch_time !== current.launch_time ||
        original.send_by_time !== current.send_by_time ||
        original.phishing_exercise_id !== current.phishing_exercise_id ||
        original.has_automatic_launch !== current.has_automatic_launch ||
        original.has_automatic_stop !== current.has_automatic_stop
      ) {
        console.log("changed");
        return true
      }
      return false

    },
    // Debounced check to emit changes
    checkForChanges: debounce(function() {
      if (this.hasSimulationChanged()) {
        const updatedSimulation = {
          ...this.localSimulation,
          employee_group_ids: this.selectedEmployeeGroupIds,
        }
        // Emit only if changes have occurred
        this.$emit("input", updatedSimulation)
      }
    }, 300),  // 300ms debounce to avoid too frequent emissions

  },
  computed: {
    loaded() {
      if (this.localSimulation && this.campaigns && this.employeeGroups && this.exercises) {
        return true
      }
      return false
    },
    noExercisesFound() {
      return this.filteredExercises.length === 0
    },
    filteredExercises() {
      if(!this.exercises) return []
      return this.exercises
        .filter((exercise) => {
          const matchesSearch =
            exercise.title.toLowerCase().includes(this.search.toLowerCase()) ||
            (exercise.theme &&
              exercise.theme.toLowerCase().includes(this.search.toLowerCase()))

          const matchesLanguage =
            this.selectedLanguage === ""
              ? true
              : exercise.lang === this.selectedLanguage

          return matchesSearch && matchesLanguage
        })
        .sort((a, b) => a.title.localeCompare(b.title))
    },
    availableLangArray() {
      const availableLangs = this.exercises.reduce((acc, exercise) => {
        if (!acc.includes(exercise.lang)) {
          acc.push(exercise.lang)
        }
        return acc
      }, [])

      return this.langArray.filter(
        (lang) => lang.code === "" || availableLangs.includes(lang.code),
      )
    },
  },
}
</script>


<template>
  <v-card elevation="0" outlined color="#fafafa" v-if="loaded">
    <div  class="main-container">
    <op-confirmation-dialog ref="confirm" :showButton="showButton" />
    <v-card-text>
      <!-- Simulation Name, Campaign, Employee Group -->
      <v-row>
        <v-col cols="4">
          <v-text-field
            :label="$t('view.companies.simulation_name')"
            v-model="localSimulation.name"
            class="required"
            :rules="[requiredRule]"
          />
        </v-col>

        <v-col cols="4">
          <v-select
            v-model="localSimulation.campaign_id"
            :items="campaigns"
            item-text="name"
            item-value="id"
            :label="$t('view.companies.select_campaign')"
            class="required"
            :disabled="localSimulation.is_active"
            :rules="[requiredRule]"
          />
        </v-col>

        <v-col cols="4">
          <v-select
            v-model="selectedEmployeeGroupIds"
            :items="employeeGroups"
            :disabled="localSimulation.is_active || localSimulation.is_stopped"
            multiple
            item-text="name"
            item-value="id"
            :label="$t('view.companies.select_employee_group')"
            class="required"
            :rules="[requiredRule]"
          />
        </v-col>
      </v-row>

      <!-- Modal for Selecting Exercise -->
      <v-row>
        <v-col cols="4">
          <v-text-field
            :disabled="localSimulation.is_active"
            v-model="selectedExerciseTitle"
            filled
            :label="$t('view.companies.select_exercise')"
            readonly
            @click="showModalExercice = true"
          >
            <template #append>
              <v-icon @click="showModalExercice = true">mdi-menu-down</v-icon>
            </template>
          </v-text-field>
        </v-col>

        <!-- Test Type -->
        <v-col cols="4" v-if="isSystemAdmin">
          <v-select
            v-model="localSimulation.is_test"
            :items="[
                  { label: 'Normal', value: false },
                  { label: 'Test', value: true }
                ]"
            item-text="label"
            item-value="value"
            filled
            :label="$t('view.companies.simulation_type')"
            class="required"
            :disabled="localSimulation.is_active"
          >
            <template #selection="{ item }">
              {{ item.label }}
            </template>
            <template #item="{ item }">
              {{ item.label }}
            </template>
          </v-select>
        </v-col>


        <!-- Tags Indicator Label and Icon -->
        <v-col cols="4" v-if="exerciseTags">
          <v-chip color="green" outlined>
            <v-icon left color="green">mdi-tag</v-icon>
            {{ $t('view.companies.exercise_with_tags') }}
          </v-chip>
        </v-col>
      </v-row>


      <!-- Date Pickers -->
      <v-row>
        <v-col cols="6">
          <op-datepicker
            ref="datepicker"
            :label="$t('Start date of shipments')"
            :date="localSimulation.launch_date"
            :minDate="todayString"
            v-on:update="updateLaunchDate"
            class="required"
            :simulationIsActive="localSimulation.is_active"
          />
        </v-col>

        <v-col cols="6">
          <op-datepicker
            :label="$t('End date of shipments')"
            :date="localSimulation.send_by_date"
            v-on:update="updateSendByDate"
            min="localSimulation.launch_date"
            :simulationIsActive="localSimulation.is_active"
            :minDate="todayString"
          />
        </v-col>
      </v-row>

      <!-- Time Pickers -->
      <v-row>
        <v-col cols="6">
          <v-time-picker
            color="green lighten-1"
            format="24hr"
            v-model="localSimulation.launch_time"
            :readonly="localSimulation.is_active"
          />
        </v-col>
        <v-col cols="6">
          <v-time-picker
            v-model="localSimulation.send_by_time"
            format="24hr"
            :readonly="localSimulation.is_active"
          />
        </v-col>
      </v-row>


      <!-- Dialog for Selecting Exercise -->
      <v-dialog v-model="showModalExercice" max-width="80%">
        <v-card>
          <v-card-title>
            <div style="width: 100%; display: inline-block">
              {{ $t("view.companies.select_exercise") }}
            </div>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('view.companies.search')"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  :items="availableLangArray"
                  v-model="selectedLanguage"
                  item-text="label"
                  item-value="code"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-container>
              <div v-if="noExercisesFound" class="text-center my-4">
                {{ $t("view.companies.no_exercises_found") }}
              </div>
              <div v-else class="d-flex flex-wrap justify-space-around">
                <v-card
                  v-for="exercise in filteredExercises"
                  :key="exercise.id"
                  class="exercise-card mb-4"
                  @click="selectExercise(exercise)"
                >
                  <v-img
                    :src="exercise.illustration_url || require('@/assets/exercice-image.png')"
                    aspect-ratio="1"
                    max-height="150"
                  />
                  <div class="title-card">{{ exercise.title }}</div>
                  <v-divider class="mx-4" />
                  <div class="subtitle">{{ $t("Theme") }}: {{ exercise.theme }}</div>
                  <div class="subtitle">{{ $t("Difficulty") }} : {{ exercise.difficulty || "-" }}</div>
                </v-card>
              </div>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn @click="showModalExercice = false" text>{{ $t("view.button.close") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <!-- Automation Section -->
      <div class="mt-6 text-h6">
        <v-icon class="mr-2">mdi-settings</v-icon>
        {{ $t("view.companies.automation") }}
      </div>
      <v-divider class="my-2"></v-divider>

      <v-row>
        <v-col class="my-0 py-0" cols="6">
          <v-switch
            v-model="localSimulation.has_automatic_launch"
            :disabled="localSimulation.is_active"
            :label="$t('Automatic start-up')"
            color="green"
          ></v-switch>
        </v-col>
      </v-row>

      <!-- Automatic Launch message -->
      <v-row class="ma-0 pa-0">
        <div v-if="localSimulation.has_automatic_launch">
          <div v-if="errorMessage">
            <span class="subtitle">{{
                $t("view.companies.it_will_not_possible_to_meet_the_deadline")
              }}</span>
          </div>
          <div v-else>
            <span class="subtitle">{{
                $t("view.companies.last_date_to_change_launch_date")
              }}:</span>
            <span><i>{{ formatDeadLineDate(localSimulation) }}</i></span>
          </div>
        </div>
      </v-row>

      <v-row>
        <v-col class="my-0 py-0" cols="6">
          <v-switch
            v-model="localSimulation.has_automatic_stop"
            :label="$t('view.companies.simulation_automatic_stop')"
            color="green"
          ></v-switch>
        </v-col>
      </v-row>

    </v-card-text>

    </div>
  </v-card>
</template>

<style scoped>
.main-container {
  min-height: 85vh;
}

.exercise-card {
  cursor: pointer;
  min-width: 220px;
  max-width: 20%;
}

.title-card {
  font-weight: bold;
  margin: 10px 0 10px 5px;
}
</style>
