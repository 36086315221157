


export default{
  data () {
    return {
      exerciceToTagsMap: [],
      exerciseTags: null // formely exerciseTags
    }
  },
  methods: {

    async initExerciseTags(simulation) {

      if(!simulation.phishing_exercise_id) return;

      const defaultTags = await this.getDefaultTags(simulation.phishing_exercise_id)

      const unparsedTags = simulation["template_tags"]
      if(!unparsedTags) {
        this.exerciseTags = JSON.parse(JSON.stringify(defaultTags))
        return;
      }

      const currentTags = JSON.parse(simulation["template_tags"])

      if (
        defaultTags &&
        currentTags &&
        this.isTagStructureIdentical(defaultTags, currentTags)
      ) {
        this.exerciseTags = JSON.parse(JSON.stringify(currentTags))
      } else {
        this.exerciseTags = JSON.parse(JSON.stringify(defaultTags))
      }
    },

    async getDefaultTags(phishingExerciseId) {

      if(!this.exerciceToTagsMap[phishingExerciseId]) {
        this.exerciceToTagsMap[phishingExerciseId] = [];
      }

      if(!this.exerciceToTagsMap[phishingExerciseId]['defaultTags']) {
        const defaultTags = await this.getCompanyExerciseTemplateTags(
          phishingExerciseId
        )
        this.exerciceToTagsMap[phishingExerciseId]['defaultTags'] = defaultTags;

      }
      return this.exerciceToTagsMap[phishingExerciseId]['defaultTags'];
    },

    isTagStructureIdentical(defaultTags, currentTags) {
      let keyTags = []
      let keyCurrentTags = []

      if (defaultTags.email) {
        keyTags = Object.keys(defaultTags.email.tags)
      }

      if (defaultTags.landingPage) {
        keyTags = keyTags.concat(Object.keys(defaultTags.landingPage.tags))
      }

      if (currentTags.email) {
        keyCurrentTags = Object.keys(currentTags.email.tags)
      }

      if (currentTags.landingPage) {
        keyCurrentTags = keyCurrentTags.concat(
          Object.keys(currentTags.landingPage.tags)
        )
      }

      const sortedTags = keyTags.slice().sort()
      const sortedCurrentTags = keyCurrentTags.slice().sort()

      return (
        sortedTags.length === sortedCurrentTags.length &&
        sortedTags
          .slice()
          .sort()
          .every(function (value, index) {
            return value === sortedCurrentTags[index]
          })
      )
    },
  }
}

