<template>
  <v-card class="mt-4" outlined>
    <v-card-title>{{
      $t("view.learning_phishing.tags_help_title")
    }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6" v-if="!noTags">
          <v-list dense>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon color="blue">mdi-format-text</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <strong>[@text|tagName1|Default Text content]</strong>
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  $t("view.learning_phishing.text_tag_subtitle")
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="
                    copyToClipboard(
                      '[@text|tagName1|Default Text content]',
                      'textCopied'
                    )
                  "
                >
                  <v-icon v-if="!textCopied">mdi-content-copy</v-icon>
                  <v-icon v-else color="green">mdi-check</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-avatar>
                <v-icon color="green">mdi-image</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <strong
                    >[@img|tagNumber4|https://example.com/image.jpg]</strong
                  >
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  $t("view.learning_phishing.img_tag_subtitle")
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="
                    copyToClipboard(
                      '[@img|tagNumber4|https://example.com/image.jpg]',
                      'imgCopied'
                    )
                  "
                >
                  <v-icon v-if="!imgCopied">mdi-content-copy</v-icon>
                  <v-icon v-else color="green">mdi-check</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" md="6"  v-if="!noTags">
          <v-list dense>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon color="orange">mdi-code-tags</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <strong>[@html|tagName2|Default HTML content]</strong>
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  $t("view.learning_phishing.html_tag_subtitle")
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="
                    copyToClipboard(
                      '[@html|tagName2|Default HTML content]',
                      'htmlCopied'
                    )
                  "
                >
                  <v-icon v-if="!htmlCopied">mdi-content-copy</v-icon>
                  <v-icon v-else color="green">mdi-check</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-avatar>
                <v-icon color="red">mdi-palette</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <strong>[@colorcode|EmailTextColor|#FF0000]</strong>
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  $t("view.learning_phishing.color_tag_subtitle")
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="
                    copyToClipboard(
                      '[@colorcode|EmailTextColor|#FF0000]',
                      'colorCopied'
                    )
                  "
                >
                  <v-icon v-if="!colorCopied">mdi-content-copy</v-icon>
                  <v-icon v-else color="green">mdi-check</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" md="6">
          <v-list dense>
            <v-list-item v-if="!noTags">
              <v-list-item-avatar>
                <v-icon color="orange">mdi-code-tags</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <strong>{{ "&lt;img src='cid:Image.jpeg'/&gt;" }}</strong>
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  $t("view.learning_phishing.CID")
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="
                    copyToClipboard(
                      `<img  src='cid:Image.jpeg'/>`,
                      'htmlCopied'
                    )
                  "
                >
                  <v-icon v-if="!htmlCopied">mdi-content-copy</v-icon>
                  <v-icon v-else color="green">mdi-check</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="!noTags"></v-divider>

            <v-list-item>
              <v-list-item-avatar>
                <v-icon color="orange">mdi-code-tags</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <strong v-pre> &lt;a href='{{.URL}}'&gt;Clickez-ici&lt;/a&gt;</strong>
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  $t("view.learning_phishing.URL")
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="copyToClipboard(`<a href='{{.URL}}'>Clickez-ici</a>`, 'tagCopied')">
                  <v-icon v-if="!colorCopied">mdi-content-copy</v-icon>
                  <v-icon v-else color="green">mdi-check</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" md="6">
          <v-list dense>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon color="blue">mdi-tag</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <strong v-pre>{{.FirstName}}</strong>
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  $t("view.learning_phishing.Firstname")
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="copyToClipboard('{{.FirstName}}', 'tagCopied')"
                >
                  <v-icon v-if="!htmlCopied">mdi-content-copy</v-icon>
                  <v-icon v-else color="green">mdi-check</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-avatar>
                <v-icon color="blue">mdi-tag</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <strong v-pre>{{.LastName}}</strong>
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  $t("view.learning_phishing.Lastname")
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="copyToClipboard('{{.LastName}}', 'tagCopied')"
                >
                  <v-icon v-if="!colorCopied">mdi-content-copy</v-icon>
                  <v-icon v-else color="green">mdi-check</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { showSnackbar } from "@/services/GlobalActions"

export default {
  name: "TagHelpCard",
  props: {
    noTags: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      textCopied: false,
      imgCopied: false,
      htmlCopied: false,
      colorCopied: false,
    }
  },
  methods: {
    copyToClipboard(text, key) {
      navigator.clipboard.writeText(text).then(() => {
        this[key] = true
        // Show the confirmation snackbar
        showSnackbar(this.$t("view.learning_phishing.copy_success"))
        setTimeout(() => {
          this[key] = false
        }, 2000)
      })
    },
  },
}
</script>
